var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [_c("v-card-title", [_vm._v("Test component for Version Log")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }